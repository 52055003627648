.mobile-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background-color: #999;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background-image: url(/assets/mobile/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  transition: opacity 100ms;
  .mask {
    position: fixed;
    top:0;right:0;left:0;bottom:0;
    background:rgba(0,0,0,0.8);
    backdrop-filter: blur(1px);
  }

  .title {
    color: white;
    text-align: left;
    padding-top: 20px;
    align-self: stretch;
    flex: 1;
    margin-left: 16px;
    text-indent: -2em;
    overflow: hidden;
  }

  .functions {
    margin-top: -50px;
    padding-top: 0;
    padding-left: 16px;
    padding-right: 16px;

    h2 {
      font-size: 22px;
      color: #fff;
    }
    p {color: #ddd;}
    .card {
      background: rgba(255,255,255,0.1);
      backdrop-filter: blur(3px);
      color: white;
    }
    .cards .item {
      width: 50%;
    }
  }
}

.mobile-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.downloads {
  text-align: center;
  margin-bottom: 90px;
}
a.downloadBtn {
  width: 205px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 300;
  border-radius: 20px;
  background-color: #00a5ff;
  border:1px solid #00a5ff;
  margin: 20px auto;
  text-decoration: none;
  img {
    margin-right: 10px;
  }
}
.downloadBtn:hover, .downloadBtn:active {
  background-color: transparent;
  border-color: white;
  color: white;
}

.mobile-slogan {
  color: #ffffff;
  width: 100vw;
  h2 {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    &::before, &::after {
      content: '';
      display: block;
      height: 1px;
      background: white;
      position: absolute;
      top: 48%;
      width: 60px;
    }
    &::before {
      left: 40px;
    }
    &::after {
      right: 40px;
    }
  }
  ul {
    display: flex;
  }
  li {
    flex: 1;
    text-align: center;
  }
  .iconfont {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0 auto 20px auto;
    border: 1px solid white;
  }
}
.footer {
  text-align: center;
  color: #999999;
  align-self: stretch;
  font-size: 12px;
  // position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index:1;
}
.footer a {
  font-size: 14px;
  color: #9999;
  line-height: 14px;
  display: inline-block;
}

/* ABOUT */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  opacity: 0.2;
  top: 0;
  min-height: 100vh;
  left: 100%;
  transition: all 100ms ease-in-out;
  width: 100%;
  padding: 100px 40px 0 40px;
  background: #000e;
  z-index: 22;
  overflow-y: scroll;
}
.about.open {
  left: 0;
  opacity: 1;
}

.description p {
  font-weight: 300;
  font-size: 16px;
  color: #ddd;
  margin-bottom: 20px;
  line-height: 1.4;
  text-align: justify;
}
.email p {
  font-size: 14px;
  line-height: 2;
  color: #666666;
  text-align: center;
  a {
    color: #666;
  }
}
