:root {
  --shadow-color: 220 3% 15%;
  --shadow-strength: 1%;
}

.functions {
  padding: 65px 165px 20px 165px;
  h2 {
    font-size: 40px;
    color: #333;
    font-weight: 400;
    margin: 32px 0 16px 0;
  }
  .cards {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap:wrap;
    margin: -10px;
  }
  .card {
    text-align: center;
  }
  h3 {
    font-size: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }
  .cards .item {
    width: 33.33%;
    display: flex;
    align-items: stretch;
  }
  .card img {
    margin: 10px auto 0 auto;
  }
  .card {
    background: white;
    margin: 10px;
    border-radius: 8px;
    padding: 16px;
    align-self: stretch;
    box-shadow: 2px 6px 16px rgba(134, 134, 134, 0.2);
  }
  p {
    margin: 0;
    color: #474747;
    text-align: left;
    font-size: 15px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 1200px) {
  .functions {
    padding-left: 60px;
    padding-right: 90px;
  }
  p {
    line-height: 1.2;
  }
}
