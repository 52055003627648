#contact.section {
  h2 {
    font-size: 40px;
    color: #333;
    font-weight: 400;
    margin: 0 0 16px 165px;
  }
  .list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 80px 165px;
    background-color: #1B1C24;
    color: #fff;
    transition: all 200ms;
  }
  .magnet {
    align-self: flex-start;
    .row {
      align-items: center;
      img {
        margin-right: 15px;
        border-radius: 10px;
      }
    }
  }
  .wechat-wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    .wechat {
      text-align: center;
      .wechat-title {
        margin-top: 24px;
        font-size: 14px;
        line-height: 1.57;
        color: #fff;
      }
    }
    .wechat:last-child {
      margin-left: 50px;
    }
  }

  p {
    line-height: 2;
    margin: 0;
  }
  .copyright {
    margin: 57px 0 0 0;
    .copyright-contact {
      margin-right: 6px;
      font-size: 18px;
      color: #fff;
      line-height: normal;
    }
    .copyright-email {
      color: #fff;
      font-size: 18px;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  .footer-record {
    padding: 20px 0;
    background-color: #0B0B0E;
    font-size: 16px;
    text-align: center;
    color: #aaa;
    a {
      color: #999;
    }
    > span {
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 1200px) {
  #contact.section {
    h2 {
    margin-left: 60px !important;
  }
}
  #contact.section .list {
    padding: 60px 90px;
  }
}

@media screen and (max-width: 960px) {
  #contact.section .list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 60px;
  }
  #contact.section .magnet {
    align-self: center;
  }
  .wechat-wrap {
    margin-top: 20px;
  }
}
