*,
 *::before,
 *::after {
   box-sizing: border-box;
 }

 html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -ms-text-size-adjust: 100%;
   -ms-overflow-style: scrollbar;
   -webkit-tap-highlight-color: transparent;
   scroll-behavior: smooth;
 }

@-ms-viewport {
  width: device-width;
}
h1,h2,h3 {margin: 0}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  background: #f5f5f5;
  font-size: 14px;
  color: #212529;
  font-family: @base-font-family;
  font-weight: 400;
  line-height: 1.5;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.root {
  max-width: @max-width;
  margin: 0 auto;
  background-color: #fff;
  min-height: 100vh;
}
.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.hide {
  display: none !important;
}
.row {
  display: flex;
  flex-direction: row;
  &.center {
    align-items: center;
  }
}

.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.clamp {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  // word-break: break-all;
  // white-space: pre-wrap;
  overflow: hidden;
}

.t-gray {
  color: #989898;
}
.t-hot {
  color: #FF7A00;
}
