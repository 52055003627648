.header {
  transition: all 200ms;
  position: sticky;
  top: 0;
  background-color: @primary-color;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 5;
  menu {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
  menu > a, .login-btn {
    transition: all 400ms;
    display: inline-flex;
    color: white;
    font-size: 16px;
    margin-right: 30px;
    font-weight: 400;
    // background-color: #3355cc;
    text-decoration: none;
    transition: all 100ms;
    border-radius: 18px;
    height: 32px;
    border: 1px solid transparent;
    align-items: center;
    padding: 0 15px;
    text-align: center;
    &:hover {
      color: #e0e0e0;
    }
  }

  .login-btn {
    position: relative;
    color: @primary-color;
    border-color: white;
    background: white;
    margin-right: 135px;
    cursor: pointer;
    z-index: 5;
    transition: margin 200ms;
    &:hover {
      background: #78d0ff;
      color: white;
      border-color: transparent;
    }
    &:hover > .dropdown-menu {
      display: block;
    }
    .dropdown-menu {
      display: none;
      position: absolute;
      right: 0;
      top: 32px;
      font-size: 14px;
      border-top: 15px solid transparent;
      ul {
        position: relative;
        margin: 0;
        padding: 0;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
        list-style-type: none;
        background-color: #fff;
        border-radius: 4px;
        a {
          display: inline-block;
          margin: 0;
          color: #333;
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          text-decoration: none;
        }
        li:hover {
          background-color: #cdeff9;
          a {
            color: @primary-color;
          }
        }
        li:first-child {
          position: relative;
          border-bottom: solid 1px #e6e6e6;
          border-top-right-radius: 2px;
          border-top-left-radius: 2px;
          &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: -12px;
            right: 35px;
            width: 0;
            height: 0;
            border-bottom: 6px solid #fff;
            border-top: 6px solid transparent;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
          }
          &:hover::after {
            border-bottom: 6px solid #cdeff9;
          }
        }
        li:last-child {
          border-bottom-right-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }
    }
  }
}

.logo {
  align-items: center;
  font-size: 21px;
  font-weight: normal;
  color: #fff;
  transition: color 200ms;
  flex-shrink: 0;
  margin-left: 135px;
  transition: margin 200ms;
  img {
    margin-right: 10px;
  }
}

.headroom--not-top.header {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  box-shadow: 0 3px 5px -3px rgba(0,0,0,0.2);
  top: 0;
  .logo {
    color: #444;
    filter: drop-shadow(0px 2px 2px #00000099);
  }
  menu > a {
    color: @primary-color;
    background-color: rgba(255, 255, 255, 0.6);
    border-color: transparent;
    &:hover {
      color: @primary-color;
    }
  }
  .login-btn {
    color: white;
    background: #00a5ffee;
    border-color: transparent;
    .dropdown-menu {
      ul {
        li:hover {
          background-color: #fff;
          a {
            color: @primary-color;
          }
        }
        li:first-child {
          &:hover::after {
            border-bottom: 6px solid #fff;
          }
        }

      }
    }
  }
}


@media (max-width: 1200px) {
  .header {
    menu a {
      margin-right: 12px;
    }
    .login-btn {
      margin-right: 60px;
    }
  }
  .logo {
    margin-left: 30px;
  }
}
