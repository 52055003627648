
#discover {
  padding: 65px 165px 20px 165px;
  position: relative;
  z-index: 3;
}
.applyBtn, .moreBtn {
  position: relative;
  height: 36px;
  width: 110px;
  text-align: center;
  background: #fff;
  border-radius: 18px;
  color: #666;
  font-size: 16px;
  border: 1px solid #666;
  font-weight: 300;
  .qrcode {
    position: absolute;
    right: -15px;
    top: 36px;
    display: none;
    z-index: 3;
    img {
      max-width: 345px;
    }
  }
  &:active, &:focus, &:hover{
    outline: none;
    .qrcode {
      display: block;
    }
  }
}

.moreBtn {
  margin-top: 20px;
}
.moreBtn:disabled {
  color: #ccc;
  border-color: #ccc;
}

.dotting {
  display: inline-block; min-width: 2px; min-height: 2px;
  // box-shadow: 2px 0 currentColor, 6px 0 currentColor, 10px 0 currentColor;
  animation: dot 2s infinite step-start;
}
@keyframes dot {
  25% { box-shadow: none; }
  50% { box-shadow: 2px 0 currentColor; }
  75% { box-shadow: 2px 0 currentColor, 6px 0 currentColor;}
  100% { box-shadow: 2px 0 currentColor, 6px 0 currentColor, 10px 0 currentColor; }
}

.discover-header  {
  justify-content: space-between;
  margin-bottom: 80px;
  h2 {
    font-size: 40px;
    color: #333;
    font-weight: 400;
    margin: 0;
  }
}
ul.communities {
  margin: -40px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    &.loading {
      text-align: center;
      width: 100%;
      .t-gray();
      padding: 50px 0;
      font-size: 20px;
    }
  }
  .community-logo {
    width: 64px;
    height: 64px;
    border-radius: 5px;
  }
  .item-inner {
    display: block;
    text-decoration: none;
    margin: 40px;
    padding: 25px 25px 0 25px;
    background: white;
    box-shadow: 2px 6px 16px rgba(134, 134, 134, 0.2);
    border-radius: 14px;
    min-height: 220px;
    height: 80%;
  }
  .item-top {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      border-radius: 5px;
    }
    .row {
      flex: 1;
    }
    .simple {
      font-size: 16px;
      .t-gray();
      .clamp();
    }
  }
  .item-bottom {
    font-size: 16px;
    border-top: 1px solid #D5D5D5;
    padding-top: 16px;
    margin-top: 16px;
    line-height: 1.8;
    .t-gray();
    .clamp();
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }

  h3 {
    font-size: 24px;
    color: #474747;
    font-weight: 400;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1200px) {
  #discover {
    padding-left: 60px;
    padding-right: 90px;
  }
}

@media screen and (max-width: 1024px) {
  ul.communities .item {
    width: 100%;
  }
}
