.banner {
  padding-bottom: 630px;
  background: linear-gradient(0deg, #00D1FF -0.06%, #00A5FF 97.14%);
  position: relative;
  transition: padding 200ms;
}
.banner-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.banner-circle {
  position: absolute;
  background-image: url(/assets/circle.svg);
  background-size: contain;
  animation: spin 5000ms linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.slogan {
  position: absolute;
  left: 165px;
  top: 20%;
  color: white;
  z-index: 4;
  transition: all 200ms;
  h2 {
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0 0 1em 0;
    font-size: 26px;
    font-weight: 300;
  }
  .iconfont {
    vertical-align: middle;
    font-size: 1em;
    background-color: white;
    color: @primary-color;
    border-radius: 50%;
    padding: 4px;
  }
  .downloadBtn {
    position: relative;
    height: 54px;
    padding: 0 40px;
    background: #FF5A5A;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
    border-radius: 27px;
    color: white;
    font-size: 20px;
    border: none;
    font-weight: 300;
    margin-top: 40px;
    .qrcode {
      position: absolute;
      left: -15px;
      top: 54px;
      display: none;
      z-index: 3;
      img {
        max-width: 345px;
      }
    }
    &:active, &:focus, &:hover {
      outline: none;
      .qrcode {
        display: block;
      }
    }
    &:hover {
      background-color: white;
      color: @primary-color;
    }
  }
}

#screenshots {
  position: absolute;
  width: 310px;
  height: 630px;
  padding-top: 18px;
  top: 30px;
  right: 165px;
  transition: all 200ms;
  z-index: 3;
  .phone {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    user-select: none;
    pointer-events: none;
  }
  .iconfont {
    position: absolute;
    top: 50%;
    color: white;
    margin-top: -50px;
    font-size: 30px;
    cursor: pointer;
    &:hover {
      color: @primary-color;
      text-shadow: 0 3px #fff;
    }
  }
  .i-left {
    left: -40px;
  }
  .i-right {
    right: -40px;
  }
}
.screenshots {
  width: 280px;
  overflow: hidden;
  border-radius: 20px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
.screenshots-inner {
  display: flex;
  flex-direction: row;
  transform: translateX(0);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  img {border-radius: 20px;}
}
.banner-angle {
  position: absolute;
  overflow: hidden;
  left: 0;
  bottom: 0;
  right: 0;
  height: 200px;
  &::before {
    content: ' ';
    display: block;
    background: #fff;
    width: 2000px;
    height: 200px;
    transform: rotate(354deg);
    transform-origin: left top;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@media screen and (max-width: 1200px) {
  .banner {
    padding-bottom: 600px;
  }
  #screenshots {
    width: 300px;
    height: 600px;
    padding-top: 18px;
    right: 80px;
  }
  .screenshots {
    width: 270px;
  }
  .slogan {
    left: 60px;
  }
}
